import { connect } from 'react-redux';

import PendingOrderSummaryV1 from 'components/PendingOrders/PendingOrderSummaryV1/PendingOrderSummaryV1';
import { confirmCancelAmendOrder } from 'redux/modules/amend-order/actions/confirm-cancel-amend-order';
import { getFailedPaymentResolutionTypeForId } from 'redux/modules/checkout/selectors/get-failed-order-payment';
import {
  getAmendOrderCutOff,
  getBranchAddress,
  getDeliveryAddress,
  getOrderTotal,
  getSlotEndTime,
  getSlotStartTime,
  getSlotType,
  getStatus,
  isAmendable,
} from 'redux/modules/orders/selectors/get-order';
import { orderIsAmending } from 'redux/modules/orders/selectors/get-order-amend-status';
import { getOrderTypeById } from 'redux/modules/orders/selectors/get-order-type-by-id';

const mapStateToProps = (state, { orderId }) => ({
  address: getDeliveryAddress(state, orderId),
  amendable: isAmendable(state, orderId),
  amending: orderIsAmending(state, orderId),
  amendOrderCutOff: getAmendOrderCutOff(state, orderId),
  branchAddress: getBranchAddress(state, orderId),
  slotEndTime: getSlotEndTime(state, orderId),
  slotStartTime: getSlotStartTime(state, orderId),
  slotType: getSlotType(state, orderId),
  orderType: getOrderTypeById(state, orderId),
  total: getOrderTotal(state, orderId),
  status: getStatus(state, orderId),
  failedPaymentResolutionType: getFailedPaymentResolutionTypeForId(state, orderId),
});

const mapDispatchToProps = dispatch => ({
  onCancelChanges: () => dispatch(confirmCancelAmendOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingOrderSummaryV1);
