import React, { ReactChild } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import { Link } from 'react-router-dom';
import { viewOrderURL } from 'constants/urls';
import { getFailedPaymentResolutionTypeForId } from 'redux/modules/checkout/selectors/get-failed-order-payment';
import {
  getAmendOrderCutOff,
  getSlotStartTime,
  getStatus,
  isAmendable,
} from 'redux/modules/orders/selectors/get-order';
import { orderIsAmending } from 'redux/modules/orders/selectors/get-order-amend-status';
import classnames from 'classnames';
import { confirmCancelAmendOrder } from 'redux/modules/amend-order/actions/confirm-cancel-amend-order';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';
import { matchOrderType } from 'utils/checkout/order-type';
import { getOrderTypeById } from 'redux/modules/orders/selectors/get-order-type-by-id';
import { isSeasonalSlotDate, seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import { Warning } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { dayjs, differenceInHours } from 'utils/date';
import orderStatus from 'constants/orderStatus';
import { GoToPaymentPageButton } from 'components/ResolveOrderPayment/GoToPaymentPageButton';
import GoToCheckout from 'components/GoToCheckout';
import styles from './OrderCardFooter.scss';

export interface OrderCardFooterProps {
  orderId: string;
}

const OrderCardFooter = ({ orderId }: OrderCardFooterProps) => {
  const dispatch = useDispatch<WtrDispatch>();
  const amendable = useSelector(state => isAmendable(state, orderId));
  const amending = useSelector(state => orderIsAmending(state, orderId));
  const amendOrderCutOff = useSelector(state => getAmendOrderCutOff(state, orderId));
  const orderType = useSelector(state => getOrderTypeById(state, orderId));
  const slotStartTime = useSelector(state => getSlotStartTime(state, orderId));
  const isSeasonalSlot = isSeasonalSlotDate(slotStartTime);
  const status = useSelector(state => getStatus(state, orderId));
  const failedPaymentResolutionType = useSelector(state =>
    getFailedPaymentResolutionTypeForId(state, orderId),
  );
  const isPaymentFailed = status === orderStatus.PAYMENT_FAILED;
  const canPayNow =
    isPaymentFailed && failedPaymentResolutionType === FailedPaymentResolutionType.selfServe;

  const isCloseToCutoff = () => {
    const diffInHours = differenceInHours(amendOrderCutOff, dayjs().tz());

    return diffInHours < 24 && diffInHours > 0;
  };

  const onCancelChanges = () => {
    dispatch(confirmCancelAmendOrder());
  };

  const getOrderStatusInfo = () => {
    /**
     * NB: this will change in the future iteration of the epic
     */
    if (isPaymentFailed && failedPaymentResolutionType !== null) {
      const content =
        failedPaymentResolutionType === FailedPaymentResolutionType.selfServe ? (
          'Payment failed. Please pay now to receive this order'
        ) : (
          <span>
            Payment could not be taken for this order. To pay now, call us on <b>0203 932 4128</b>
          </span>
        );

      return (
        <div className={styles.statusWithIcon}>
          <Warning className={styles.errorIcon} aria-hidden />
          <>{content}</>
        </div>
      );
    }

    if (!amendable) {
      return 'Unable to amend – order in progress';
    }

    if (isSeasonalSlot) {
      return (
        <>
          Amend cut-off for Christmas Entertaining items is{' '}
          <strong>{seasonalCutOffDateTime}</strong>
        </>
      );
    }

    if (amending) {
      return (
        <>
          Checkout before <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong>
        </>
      );
    }

    return matchOrderType<ReactChild>(
      orderType,
      {
        onGroceries: () => (
          <>
            You have until <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong> to amend
            this order
          </>
        ),
        onEntertaining: () =>
          'Please check individual notice times before amending Entertaining orders',
        onGroceriesEntertaining: () => 'Notice times vary for Entertaining items',
      },
      null,
    );
  };

  const getButtons = () => {
    const url = `${viewOrderURL(Number(orderId))}`;

    if (amending) {
      return (
        <div className={styles.actionButtons}>
          <TextLink
            underline="always"
            component="button"
            data-testid={`discard-amends-button-${orderId}`}
            onClick={() => onCancelChanges()}
            light
          >
            Discard amends
          </TextLink>
          {/*
           * this will need to be visually updated, pending design  mocks
           */}
          <GoToCheckout
            theme="secondaryLight"
            messagePlacement="topPopover"
            location="My orders page"
            buttonCheckoutId={`my-orders-page-checkout-${orderId}`}
            canShowInstantCheckoutModal
            useLightTooltip
          />
        </div>
      );
    }
    return (
      <div className={styles.actionButtons}>
        {canPayNow && (
          <GoToPaymentPageButton customerOrderId={orderId} className={styles.payNowButton} />
        )}
        <LinkAsButton
          component={Link}
          data-testid={`view-order-button-${orderId}`}
          className={styles.viewOrAmendOrderButton}
          to={url}
          label={amendable && !isPaymentFailed ? 'View or amend order' : 'View order'}
          theme={amendable && !isPaymentFailed ? 'primary' : 'secondary'}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames([
        styles.orderCardFooter,
        {
          [styles.closeToCutOff]: isCloseToCutoff() && !isPaymentFailed,
          [styles.orderCardFooterDark]: amending,
        },
      ])}
      data-testid="order-status-info-wrapper"
    >
      <Typography
        element="p"
        styleAs="paragraphSmallLight"
        noMargins
        className={styles.orderStatusInfo}
        data-testid={`order-status-info-${orderId}`}
      >
        {getOrderStatusInfo()}
      </Typography>

      <div>{getButtons()}</div>
    </div>
  );
};

export default OrderCardFooter;
