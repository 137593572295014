import {
  LinkAsButton,
  LinkAsButtonProps,
} from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import React from 'react';

import { dataLayer } from 'analytics/data-layer';
import { resolveOrderPaymentURL } from 'constants/urls';
import { preserveStubsUrl } from 'utils/general';

export interface GoToPaymentPageButtonProps {
  customerOrderId: string;
  className?: string;
  width?: LinkAsButtonProps['width'];
}

function sendPayNowGaEvent() {
  dataLayer.push({ event: 'click_pay_now' });
}
export function GoToPaymentPageButton({
  customerOrderId,
  className,
  width,
}: GoToPaymentPageButtonProps) {
  const href = preserveStubsUrl(resolveOrderPaymentURL(customerOrderId));

  return (
    <LinkAsButton
      href={href}
      width={width}
      label="Pay now"
      className={className}
      onClick={sendPayNowGaEvent}
    />
  );
}
