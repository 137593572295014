import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import orderStatus from 'constants/orderStatus';
import { Clock, Pen } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useSelector } from 'react-redux';
import { getStatus } from 'redux/modules/orders/selectors/get-order';
import styles from './OrderStatus.scss';

export interface OrderStatusProps {
  orderId: string;
}

const OrderStatus = ({ orderId }: OrderStatusProps) => {
  const status = useSelector(state => getStatus(state, orderId));

  if (!status) {
    return null;
  }

  const isOrderAmending = status === orderStatus.AMENDING;

  const icon = () => {
    if (isOrderAmending) {
      return <Pen className={styles.amendingIcon} size="medium" />;
    }
    return <Clock className={styles.pendingIcon} size="medium" />;
  };

  return (
    <div className={styles.orderStatus}>
      {icon()}
      <Typography styleAs="paragraphExtraSmall" noMargins className={styles.orderStatusLabel}>
        {isOrderAmending ? 'AMENDING' : 'PENDING'}
      </Typography>
    </div>
  );
};

export default OrderStatus;
