import React from 'react';
import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import styles from './OrderCard.scss';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderCardFooter from './OrderCardFooter/OrderCardFooter';
import OrderStatus from './OrderStatus/OrderStatus';

export interface OrderCardProps {
  orderId: string;
}

const OrderCard = ({ orderId }: OrderCardProps) => {
  if (!orderId) {
    return null;
  }

  return (
    <Card
      data-testid={`pending-order-summary-id-${orderId}`}
      footer={<OrderCardFooter orderId={orderId} />}
    >
      <div className={styles.orderCardContent}>
        <OrderStatus orderId={orderId} />
        <OrderDetails orderId={orderId} />
      </div>
    </Card>
  );
};

export default OrderCard;
